/* Body */
body {
  color: #1b253c;
}

.shadow {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.12) !important;
}

hr {
  border: 0;
  border-top: 0.05rem solid rgba(200, 200, 200, 0.4) !important;
}
/* Hero and Nav */

.navbar-brand img {
  height: 52px;
}

.headroom {
  background: #bababa;
}

.navbar-collapse.collapsing,
.navbar-collapse.show {
  background: #EEC643;
}

.lead.full {
  font-weight: 500;
}

/* Footer */

@media (min-width: 1200px) {
  .nav-footer.nav {
    justify-content: flex-end;
  }
}
@media (max-width: 1199px) {
  .nav-footer.nav {
    justify-content: flex-end;
  }
}
@media (max-width: 575px) {
  .copyright,
  .nav-footer.nav {
    justify-content: center;
    text-align: center;
  }
}

.copyright {
  padding: 1rem 0;
}

/* Layout and Sections */

#root {
  height: 100%;
}

.container.body-content {
  margin-top: 7rem;
  margin-bottom: 5rem;
}

.full-height {
  height: 100vh;
}

.flex > .active,
.flex {
  display: flex;
  flex-direction: row;
}

.price-section {
  background-color: #6BC770;
}

.how-it-works .jumbotron {
  background-image: linear-gradient(126deg, #7ff6f6 5%, #b5f5ff 39%,#f7feff 72%)
}

.k-plus-loop {
  /* border: 10px solid white; */
  border: 0;
  margin: 0 auto 1rem;
}

.section-shaped .shape-style-1.shape-default {
  background-color: #3d7694;
  background-image: linear-gradient(156deg, #3d7694 0%, #42ac48 19%, #5ea6d4 39%, #9792e3 60%, #eec643 80%, #fafafa 100%);
}

.section-shaped .shape-style-2.shape-default {
  background-color: #3d7694;
  background-image: linear-gradient(156deg, #7ab6d6 0%, #5ea6d4 39%, #5ea6d4 49%,#9792e3 82%);
}

.section-shaped .shape-style-3.shape-default {
  background-color: #3d7694;
  background-image: linear-gradient(126deg, #7ab6d6 5%, #9ed2f3 49%,#cfcdf3 82%);
}


.jumbotron-clear {
  background: none;
}

.section-hero .jumbotron {
  color: white;
  margin-top: 4.5rem;
}

.section-hero .jumbotron h1 {
  color: white;
  font-weight: bolder;
}

/* Fonts */

a:not(.nav-link,.btn){
  font-weight: bolder;
  text-decoration: underline;
}

h4 {
  font-weight: 800;
}

.white {
  color: white !important;
}

p, .paragraph {
  line-height: 2;
}

.alert {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  padding: 2rem 5rem;
  text-align: center;
  box-shadow: 0.5px -2px 35px #111;
}

/* Buttons */

.btn, .btn-default {
  color: white;
  background-color: #544eac;
}

.btn:hover {
  opacity: 0.88;
  color: white;
  background-color: #625cb3;
}

.btn-neutral {
  color: white;
  background-color: #6a65aa;
}

#menu-signin {
  background-color: #544eac;
}

/* Image */

.image-section {
  overflow: unset;
}

.hero-image {
  border-radius: 5px;
  margin-top: -215px;
  width: 98%;
  max-width: 720px;
  box-shadow: 0.5rem 0.5rem 1.65rem rgb(0 0 0 / 35%)
}

.fill-purple {
  fill: #a39df0;
}
.fill-gray {
  fill: #f4f5f7
}
.fill-green {
  fill:#6BC770;
}

/* Container @media query overrides */
@media (min-width: 1200px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 760px;
  }
}

/* Custom Cards */

.card {
  padding: 1.75rem 1rem;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
}

.card.code-snippet {
  background: #fafafa;
  flex-direction: row;
}

.card.code-snippet strong {
  background: none;
}

.left,
.right {
  display: flex;
  flex-grow: 1;
  display: block;
  flex-basis: 50%;
}

.card strong {
  padding: .20rem .45rem;
  border-radius: 0.15rem;
  background: rgb(137, 130, 245, 0.4);
  background-image: linear-gradient(175deg, #dff2fc 5%, #d1e8f5 49%,#b0e0fa 82%);
}

.card.pricing-details {
  background: rgba(255,255,255, 0.7);
  border: 1px solid rgba(255,255,255, 0.8);
}

/* Pricing */

.post-image-section {
  margin-top: -32px;
  background-color: #a5a0f0;
}

.col-4 {
  padding-right: 8px;
  padding-left: 8px;
}

.no-decoration {
  text-decoration: none !important;
}

/* Pricing Info */

.pricing-info .card {
  color: white;
  border: none !important;
  border-radius: 10px;
  margin-bottom: 0;
  padding: 1.5rem 1rem;
}
@media (min-width: 1200px) {
  .pricing-info .card {
    padding: 2.5rem 2rem;
    min-height: 17.5rem !important;
  }
}
@media (max-width: 1199px) {
  .pricing-info .card {
    padding: 1.25rem 1rem;
    min-height: 18rem !important;
  }
  .pricing-info .card .body {
    padding: 1.5rem 1rem;
  }
}
@media (max-width: 800px) {
  .pricing-info .card {
    padding: 1.25rem 1rem;
    min-height: 18.25rem !important;
  }
}
@media (max-width: 575px) {
  .copyright,
  .pricing-info .card {
    min-height: 14rem !important;
  }
  .pricing-info .card .body {
    padding: 1rem;
  }
}
.pricing-info .card.starter {
  background-color: #C1BEEE;
}
.pricing-info .card.growth {
  background-color: #847DDE;
}
.pricing-info .card.enterprise {
  background-color: #554dd1;
}
.pricing-info .card .card-body {
  font-weight: bolder;
  margin: auto;
}
.pricing-info .card .description {
  font-weight: 600;
  font-size: 1rem;
}
.pricing-info .card h3,
.pricing-info .card h2,
h4 {
  color: white !important;
}

/* Landing */

.adding  .description {
  font-size: 1rem;
}

/* Account */

.profile-photo {
  border-radius: 100%;
  height: 32px;
}

#connect-account {
  width: fit-content;
  display: block;
}

/* Receipts */

.receipts-table.table td,
.receipts-table.table th {
  padding: unset;
}

/* Contact */

.contact-us .left {
  flex-basis: 80%;

}
.contact-us .right {
  flex-basis: 20%;
}

/* Alert Message */

.alert-message {
  z-index: 999;
}

/* Stripe */

.stripe-payment {
  width: 80%;
  padding: 1rem 0;
}

.stripe-payment #card-element {
  padding: 1.25rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #bababa;
}

.stripe-payment #card-errors {
  color: #fa755a;
  font-weight: 600;
  padding: 0.25rem 1.5rem;
}
